






























import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import timeFilter from './timeFilter.vue'
const columns = [
    {
        title: '预警内容',
        key: 'content',
    },
    {
        title: '预警发生时间',
        slot: 'addTime',
    },
    {
        title: '预警状态',
        slot: 'state',
    },
    {
        title: '操作',
        slot: 'action',
        // width: 400
    },
]
export default Vue.extend({
    components: {
        timeFilter,
    },
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            columns,
            data: {
                total: 0,
                list: [],
            },
            formData: {},
        }
    },
    methods: {
        getData() {
            this.loading = true
            const { pageNum, pageSize } = this
            const timeFilterRef: any = this.$refs.timeFilter
            const formList = timeFilterRef.getData()
            this.formData = { ...this.formData, ...formList}
            const params = { pageNum, pageSize, ...this.formData}
            request.get('/api/respond/forewarning/page', params)
            .then((data) => {
                this.data = data
                this.loading = false
            })
        },
        cancelItem(id: string) {
            request.put(`/api/respond/forewarning/cancel/${id}`)
            .then((res) => {
                // this.getWarnNumber()
                this.$Message.success('取消成功')
                this.getData()
            }).catch((errorMsg) => {
                this.$Message.error(errorMsg)
            })
        },
        reviewItem(item: any) {
            this.$store.commit('setCallIndex', 2)
            this.$router.push({
                name: 'callInfo',
                params: {...item},
            })
        },
        /* 改变pageNum */
        changPageNum(num: number) {
            this.pageNum = num
            this.getData()
        },
        // getWarnNumber() {
        //     request.get('/api/respond/forewarning/count_by_zero', null)
        //     .then((data) => {
        //         this.$store.commit('setWarnNum', data)
        //     })
        // },
    },
    mounted() {
        this.getData()
    },
    filters: {
        formatTime(value: any) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        getState(value: number) {
            if (value === 0) {
                return '待审核'
            }
            if (value === 1) {
                return '审核通过'
            }
            return '取消'
        },
    },
})
